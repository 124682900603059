import img1 from './1.JPG'
import img2 from "./2.JPG";
import img3 from "./3.JPG";
import img4 from "./4.JPG";
import img5 from "./5.jpg";
import img6 from "./6.jpg";
import img7 from "./7.jpg";
import img8 from "./8.jpg";
import img9 from "./9.jpg";
import img10 from "./10.jpg";

const images = {
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10
}

export default images