import img1 from "./1.JPG";
import img2 from "./2.JPG";
import img3 from "./3.JPG";
import img4 from "./4.JPG";
import img5 from "./5.JPG";


const images = {
  img1,
  img2,
  img3,
  img4,
  img5,
};

export default images;